import { ApolloProvider } from "@apollo/client";
import { LDProvider, useFlags } from "launchdarkly-react-client-sdk";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import { createApolloClient } from "./config/apollo";
import { LauncDarklyInstance } from "./config/launchDarkly";
import ContextProvider from './context'
import { Router } from './pages/Router'
import { LaunchDarklyFlags } from "./shared/typings/launchDarklyFlags";

function App() {
  const ldConfig = {
    clientSideID: process.env.REACT_APP_LD_CLIENT_KEY ?? '',
  }

  const flags = useFlags() as LaunchDarklyFlags

  return (
    <BrowserRouter>
      <LDProvider {...ldConfig}>
        <React.StrictMode>
          <ApolloProvider client={createApolloClient(flags)}>
            <ContextProvider>
              <LauncDarklyInstance>
                <Router />
              </LauncDarklyInstance>
            </ContextProvider>
          </ApolloProvider>
        </React.StrictMode>
      </LDProvider>
    </BrowserRouter>
  );
}

export default App;
