const paths = {
  CALLBACK: '/callback',
  OHM_CALLBACK: '/ohm-callback',
  KIOSK_CALLBACK: '/kiosk/callback',
  CONTACT_US: '/contact-us',
  FEEDBACK: '/feedback',
  SITES_LIST: '/sites-list',
  ROOT: '/',
  ROOT_SETTINGS_WIFI: '/info/settings/wifi',
  INFO_DASHBOARD: '/info',
  INFO_ENERGY_GRAPH: '/info/energy-graph/',
  INFO_CURRENT_POWER: '/info/current-power/',
  INFO_SYSTEM_INFO: '/info/system-info/',
  INFO_ENVIRONMENTAL_SAVINGS: '/info/environmental-savings/',
  INFO_BILL_SAVINGS: '/info/bill-savings/',
  INFO_STORAGE: '/info/storage/',
  INFO_STORAGE_EMPTY: '/info/storage/empty',
  PAYMENTS: '/payments',
  PROFILE: '/profile',
  PANELS: '/panels',
  PROFILE_INFO_MY_ACCOUNT: '/profile/info',
  PROFILE_REPORTS_SETUP: '/profile/reports-setup',
  PROFILE_REPORTS_INFO: '/profile/reports-info',
  PROFILE_SETTINGS_WIFI: '/profile/settings/wifi',
  PROFILE_ABOUT_MODAL: '/profile/info',
  PROFILE_EDIT_MODAL: '/profile/edit',
  ALERT_ICD_MODAL: '/alert/icd',
  ALERT_ICE_MODAL: '/alert/ice',
  ALERT_ID_MODAL: '/alert/id',
  ALERT_CUSTOM_MODAL: '/alert/custom',
  ALERT_LEGAL_DOCUMENT_MODAL: '/alert/legal-document',
  ALERT_OPTED_OUT: '/alert/opted-out',
  ALERT_END_CHAT: '/alert/end-chat',
  STORAGE_EDIT_MODAL: '/storage/edit',
  STORAGE_EDIT_MODAL_COSTS: '/storage/edit-costs',
  COST_SAVINGS_MODE: '/storage/cost-savings-mode',
  UTILITY_RATE_PLAN_DETAIL: '/storage/utility-rate-plan-detail',
  UTILITY_RATE_PLAN_SCHEDULES: '/storage/utility-rate-plan-schedules',
  SELF_SUPPLY_MODE: '/storage/self-supply-mode',
  SELF_SUPPLY_SETUP: '/storage/self-supply-mode-setup',
  RESERVE_MODE: '/storage/reserve-mode',
  RESERVE_SETUP_STEP_1: '/storage/reserve-mode-step-1',
  RESERVE_SETUP_STEP_2: '/storage/reserve-mode-step-2',

  MONITORING_ADMIN_LOGIN: '/admin',
  F_A_Q: '/faq',
  F_A_Q_CONNECTED_SOLUTIONS: '/faq-connected-solutions',
  F_A_Q_PANELS: '/faq-panels',
  F_A_Q_OHM_CONNECT: '/faq-ohm-connect',
  F_A_Q_DYNAMIC: '/faq-dynamic',
  LIFE_EVENTS: '/life-events',
  LOGIN: '/login',
  API_ERROR: '/api/error',
  PROFILE_API_ERROR: '/profile/api/error',
  PROFILE_REPORTS_API_ERROR: '/reports/api/error',
  PENDING_SYSTEM: '/pending-system',
  VIRTUAL_PROGRAMS_LIST: '/programs-power-plants',
  VIRTUAL_PROGRAM_ENROLL: '/enroll-power-plants',
  VIRTUAL_PROGRAM_ENROLL_STATUS: '/enroll-power-plants-status',
  CONNECTED_SOLUTIONS: '/connected-solutions',
  MARKETING_CONNECTED_SOLUTIONS: '/mkt-connected-solutions',
  MARKETING_OHM_CONNECT: '/mkt-ohm-connect',
  MARKETING_VPP_DYNAMIC: '/mkt-power-plants',
  RESERVE_DYNAMIC_VPP: '/reserve-level',
  OHM_CONNECT: '/ohm-connect',
  DOCUSIGN_EVENT: '/enroll-power-plants/:callback?',
  VPP_DASHBOARD: '/virtual-power-plant',
  ENROLLMENT_DOCUMENTS: '/enrollment-documents',
  HELP_CENTER_CHAT: '/help-center-chat',
}
export default paths
