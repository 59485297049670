import './styles.scss'

import moment from 'moment'
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useHistory } from 'react-router-dom'

import { Layout } from '../../../components/layout'
import { Loader } from '../../../components/loader'
import { Modal } from '../../../components/modal'
import { useSupportCases } from '../../../shared/hooks'
import { useChat } from '../../../shared/hooks/useChat'
import paths from '../../Router/paths'
import CloseImg from '../assets/close.svg'
import EndChatIl from '../assets/end-chat-il.svg'
import { CasesSlider, ChatCard } from '../components'

export const HelpChat = ({ modal }: { modal?: JSX.Element }) => {
  const history = useHistory()

  const {
    providedChatSession,
    setProvidedChatSession,
    loading,
    messages,
    chatDate,
    isChatEnded,
    textChat,
    handleOnClickSend,
    setTextChat,
    handleOnClickNewChat,
  } = useChat()

  const { loading: casesLoading, cases } = useSupportCases()

  const ref = useRef<HTMLDivElement>(null)

  const [casesOpen, setCasesOpen] = useState<boolean>(false)
  const handleOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => setTextChat(e.target.value),
    [],
  )

  useEffect(() => {
    setTimeout(() => {
      ref.current?.scrollIntoView({ behavior: 'smooth' })
    }, 500)
  }, [messages.length])

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === 'Enter' && textChat !== '') {
        handleOnClickSend()
      }
    },
    [handleOnClickSend, textChat],
  )
  const handleToogleCases = useCallback(
    () => setCasesOpen((prevState) => !prevState),
    [],
  )
  const handleClickOnEndChat = useCallback(() => {
    history.push(`${paths.HELP_CENTER_CHAT}${paths.ALERT_END_CHAT}`)
  }, [history])

  const confirmEndChat = useCallback(() => {
    providedChatSession?.endChat(() => {
      setProvidedChatSession(undefined)
      history.goBack()
      setTimeout(() => {
        ref.current?.scrollIntoView({ behavior: 'smooth' })
      }, 500)
    })
  }, [history, providedChatSession, setProvidedChatSession])

  return (
    <Layout
      title="Help Center"
      containerClassName="help-chat-container"
      sectionClassName="help-chat-section"
      headerOptions={{
        children: {
          right: casesOpen ? (
            <div className="layout-cases-button" onClick={handleToogleCases}>
              <img src={CloseImg} alt="close drawer" />
            </div>
          ) : (
            <div className="layout-cases-button" onClick={handleToogleCases}>
              My cases
            </div>
          ),
        },
        hiddenMenu: casesOpen,
      }}
    >
      <div className="help-chat-section">
        {loading ? (
          <Loader />
        ) : (
          <div className="main-container">
            <div className="chat-container">
              <div className="chat-elements-wrapper">
                {!isChatEnded && (
                  <div className="end-chat">
                    <button onClick={handleClickOnEndChat}>End Chat</button>
                  </div>
                )}
                {chatDate !== 0 && (
                  <div className="date-chat">
                    {moment.unix(chatDate).format('MM/DD/YY')}
                  </div>
                )}
                <div className="conversation-container">
                  {messages.map((item, index) => (
                    <ChatCard
                      key={`${item?.id}-${index}`}
                      {...{ item, index }}
                    />
                  ))}
                  {isChatEnded && (
                    <div className="chat-ended-container">
                      <img src={EndChatIl} alt="chat ended il" />
                      <div className="ended-chat-label">
                        Your chat has been ended.
                      </div>
                      <button onClick={handleOnClickNewChat}>New Chat</button>
                    </div>
                  )}
                  <div ref={ref} />
                </div>
              </div>
              <div className="input-container">
                <input
                  className="input"
                  name="postContent"
                  placeholder="What can we do to help you?"
                  onChange={handleOnChange}
                  value={textChat}
                  onKeyDown={handleKeyDown}
                  disabled={isChatEnded}
                />
                <button onClick={handleOnClickSend} disabled={!textChat}>
                  Send
                </button>
              </div>
            </div>
            <CasesSlider
              open={casesOpen}
              loading={casesLoading}
              cases={cases}
            />
          </div>
        )}
        {modal && (
          <Modal
            hideTopBar
            title=""
            fromPath={paths.HELP_CENTER_CHAT}
            noStylesModal
            modalProps={{
              callback: confirmEndChat,
            }}
          >
            {modal}
          </Modal>
        )}
      </div>
    </Layout>
  )
}
